<content-loading-list *ngIf="loadingContent"></content-loading-list>
<div class="w-full  flex flex-wrap" *ngIf="!loadingContent && pager.total > 0">
  <div class="inline-block min-w-full py-2 align-middle">
    <div class="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2">
      <table class="min-w-full divide-gray-100 divide-y-[3px]">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">
              Canal
            </th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">
              Protocolo
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
              Data de Início
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
              Responsável
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
              Departamento
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
              Status
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
            </th>
          </tr>
        </thead>
        <tbody class="divide-gray-100 divide-y-[3px] bg-white">
          <tr class="rounded-none" *ngFor="let item of pager.list">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <div class="flex gap-1 items-center">
                @if (item.channel?.type === 'CLOUD_API') {
                <svg id="Camada_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 435.42 445.98" width="20"
                  height="20">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #fdfdfe;
                      }

                      .cls-2 {
                        fill: #039855;
                      }

                      .cls-3 {
                        fill: #49acf3;
                      }
                    </style>
                  </defs>
                  <g id="Camada_1-2" data-name="Camada_1">
                    <g id="icon-whatsapp-verificado">
                      <g>
                        <g>
                          <path class="cls-2"
                            d="M11.21,445.98h-5.62c-4.43-2.9-5.69-6.8-4.59-12.02,6.77-32.29,13.31-64.63,20.1-96.91.82-3.88.46-7.14-1.22-10.72C.17,284.53-4.91,240.96,4.75,195.78,28.55,84.48,140.53,14.08,251.15,40.78c101.66,24.54,168.7,122.01,154.59,224.94-5.42,39.53-20.79,74.84-46.97,104.99-40.33,46.43-91.49,70.55-153.01,71.85-30.78.65-60.24-5.94-88.13-19.11-3.06-1.44-5.82-1.83-9.19-1.02-23.1,5.6-46.28,10.88-69.38,16.5-9.3,2.26-18.79,3.8-27.84,7.05ZM210.89,79.42c-34.02.2-59.52,6.44-83.15,19.41-31.46,17.27-54.86,42.26-69.55,75.11-13.47,30.14-16.98,61.58-11.32,94.05,3.27,18.76,10.43,36.13,19.57,52.72,1.64,2.98,2.01,5.77,1.28,9.15-3.94,18.28-7.64,36.61-11.41,54.92-.93,4.51-.7,4.73,3.67,3.7,14.56-3.43,29.28-6.29,43.61-10.51,10.1-2.97,18.45-1.79,27.98,2.94,48.92,24.27,98.7,24.42,146.66-1.28,51.84-27.78,80.08-72.49,84.44-131.46,2.42-32.8-5.07-63.58-22.48-91.47-31.4-50.28-77.42-75.33-129.29-77.29Z" />
                          <path class="cls-2"
                            d="M259.79,337.99c-22.01-.11-39.65-5.69-56.03-14.9-36.66-20.63-66.79-48.33-88.7-84.48-7.05-11.63-11.36-24.34-13.36-37.91-2.82-19.16,3.23-34.99,16.77-48.34,2.67-2.63,5.36-5.23,8.2-7.67,7.7-6.61,18.69-4.82,23.06,4.29,6.06,12.64,11.55,25.56,17.04,38.46,2.26,5.32,1.19,10.5-2.33,15.11-2.43,3.19-4.96,6.31-7.32,9.55-4.66,6.39-4.78,12.39-.25,18.88,11.29,16.18,25.91,29.1,41.12,41.38,7.18,5.8,14.69,11.13,23.02,15.23,6.96,3.42,12.74,2.68,18.36-2.67,2.81-2.67,5.49-5.48,8.18-8.27,4.7-4.88,10.27-6.45,16.81-4.56,12.44,3.6,24.93,7.05,37.32,10.82,11.24,3.42,14.79,14.06,8.14,23.81-3.01,4.42-6.02,8.84-9.64,12.8-12.64,13.82-24.06,18.67-40.41,18.47Z" />
                        </g>
                        <g>
                          <path class="cls-3"
                            d="M329.46,20.19c2.48.05,4.82.2,7.16-.19.97-.16,1.62-.51,2.09-1.44.97-1.94,1.54-4,2.14-6.07.7-2.43,1.47-4.85,2.71-7.08,2.85-5.13,7.74-6.72,13.04-4.22,2.62,1.23,4.87,3.02,7.14,4.77.83.64,1.72,1.22,2.53,1.88,2.35,1.92,4.57,1.52,6.84-.19,2.44-1.84,4.77-3.84,7.41-5.41,2.68-1.6,5.48-2.77,8.7-1.99,3.53.86,5.55,3.4,6.97,6.51,1.04,2.26,1.76,4.64,2.39,7.05.42,1.63,1.07,3.16,1.76,4.68.46,1.01,1.18,1.35,2.2,1.51,3.81.59,7.65-.06,11.46.26,2.76.23,5.41.79,7.54,2.72,3.4,3.08,3.34,6.97,2.41,11.01-.53,2.28-1.46,4.45-2.14,6.68-1.91,6.25-2.06,5.78,3.18,9.6,2.32,1.69,4.78,3.19,6.84,5.21,4.76,4.66,4.8,10.34.11,15.04-2.04,2.04-4.49,3.56-6.81,5.23-5.41,3.9-5.23,3.47-3.22,9.81.95,3,2.2,5.91,2.55,9.08.63,5.73-2.44,9.86-8.12,10.9-2.9.53-5.82.35-8.73.31-1.53-.02-3.05.02-4.58.18-1.51.16-2.37.85-2.98,2.21-1.18,2.64-1.68,5.48-2.66,8.17-.92,2.52-1.88,5.02-3.85,6.97-2.54,2.5-5.53,3.28-8.95,2.3-3.54-1.01-6.32-3.31-9.15-5.52-1.29-1-2.64-1.87-4.04-2.72-1.31-.8-2.43-.71-3.72.03-1.71.98-3.31,2.1-4.76,3.42-2.75,2.5-5.95,4.15-9.58,4.93-3.72.8-6.54-.75-8.71-3.63-2.21-2.92-3.09-6.43-4.07-9.88-.44-1.57-1.03-3.07-1.69-4.56-.48-1.08-1.21-1.52-2.38-1.7-3.01-.47-6.02-.04-9.03-.17-1.48-.06-2.95-.06-4.43-.33-6.23-1.13-9.41-5.77-8.19-12.03.5-2.59,1.33-5.08,2.27-7.54.67-1.75,1.12-3.54,1.34-5.4.09-.73-.13-1.26-.65-1.78-1.23-1.22-2.55-2.31-4-3.25-2.29-1.48-4.55-3.01-6.5-4.94-4.82-4.77-4.83-10.37-.04-15.14,1.91-1.9,4.13-3.4,6.38-4.87,1.32-.87,2.53-1.87,3.73-2.9,1.06-.91,1.22-1.91.99-3.18-.4-2.18-1.22-4.22-1.95-6.28-.78-2.21-1.5-4.43-1.75-6.78-.62-5.74,2.43-9.87,8.08-10.94,2.28-.43,4.57-.32,6.72-.36ZM392.53,56.9c-.08-1.83-.91-3.25-2.53-4.05-1.84-.9-3.6-.57-5.24.67-6.56,4.96-13.17,9.86-19.7,14.86-1.03.79-1.52.61-2.32-.22-2.59-2.69-5.26-5.3-7.9-7.93-2.11-2.09-4.83-2.21-6.76-.32-1.92,1.89-1.84,4.67.23,6.75,3.85,3.87,7.71,7.72,11.57,11.57,2.06,2.05,4.24,2.27,6.56.54,8.09-6.03,16.15-12.1,24.22-18.16,1.22-.92,1.81-2.19,1.87-3.72Z" />
                          <path class="cls-1"
                            d="M392.53,56.9c-.06,1.53-.65,2.8-1.87,3.72-8.06,6.06-16.13,12.13-24.22,18.16-2.32,1.73-4.5,1.51-6.56-.54-3.87-3.85-7.72-7.7-11.57-11.57-2.07-2.08-2.16-4.87-.23-6.75,1.93-1.89,4.65-1.78,6.76.32,2.65,2.63,5.31,5.24,7.9,7.93.8.83,1.3,1.01,2.32.22,6.53-5,13.14-9.9,19.7-14.86,1.64-1.24,3.4-1.58,5.24-.67,1.62.8,2.45,2.22,2.53,4.05Z" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                } @else {
                <img src="../../../../../../../assets/images/icon-wp.svg" alt="icon not oficial" width="20"
                  height="20" />
                }
                <span class="text-[16px]">{{ item.channel?.name | truncate : 15 }}</span>
              </div>
            </td>
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <div class="bg-[#BDBDBD] text-white rounded-lg w-[180px] py-1 text-center">
                {{ item.protocol }}
              </div>
            </td>
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <div>
                {{ item?.createdAt | date : "dd/MM/yyyy HH:mm:ss" }}
              </div>
            </td>
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <div *ngIf="item?.user">
                <!-- <img class="w-8 h-8 rounded-full" [src]="getUserImage(item?.user)" alt="{{ item?.user?.name }}" /> -->
                {{ item?.user?.name }}
              </div>
            </td>
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <div class="flex gap-3">
                {{ item?.department?.name }}
              </div>
            </td>
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
              <span *ngIf="item.status === 'OVERTIME'">
                <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': '#EF4444' }">
                  Fora de horário
                </span>
              </span>
              <span *ngIf="item.status === 'PENDING'">
                <span class="px-[10px] py-[5px] font-dark text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': 'orange' }">
                  Aguardando
                </span>
                <span *ngIf="item.chatbotStatus.automated"
                  class="ml-2 px-[10px] py-[5px] font-dark text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': 'black' }">
                  Chatbot
                </span>
              </span>
              <span *ngIf="item.status === 'IN_PROGRESS'">
                <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': 'green' }">
                  Em andamento
                </span>
              </span>
              <span *ngIf="item.status === 'PAUSED'">
                <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': '#3B82F6' }">
                  Pausado
                </span>
              </span>
              <span *ngIf="item.status === 'CLOSED'">
                <span class="px-[10px] py-[5px] font-light text-white rounded-[5px]"
                  [ngStyle]="{ 'background-color': '#667085' }">
                  Fechado
                </span>
              </span>
            </td>
            <div class="inline-block text-left  mt-[35px]" x-data="{ menu: false }">
              <button x-on:click="menu = ! menu" type="button"
                class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button"
                aria-expanded="true" aria-haspopup="true">
                <span class="sr-only"></span>
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                  aria-hidden="true">
                  <path
                    d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                </svg>
              </button>
              <div x-show="menu" x-on:click.away="menu = false"
                class="origin-top-right absolute rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="" role="none">
                  <a (click)="openAssignedUserTransferModal(item)" *ngIf="showTransferButton(item)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm cursor-pointer"
                    role="menuitem" tabindex="-1" id="menu-item-0">
                    Transferir
                  </a>
                  <a (click)="showDetailsAttendanceHitory(item)"
                    class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm cursor-pointer"
                    role="menuitem" tabindex="-1" id="menu-item-0">
                    Histórico de Atendimento
                  </a>
                </div>
              </div>
            </div>
          </tr>
          <tr class=""></tr>
        </tbody>
      </table>

    </div>
  </div>
</div>
<empty-records-component *ngIf="!loadingContent && !pager.list?.length"
  [message]="'Nenhum atendimento encontrado para este usuário.'"
  [subMessage]="'Clique em  Abrir atendimento para iniciar um novo atendimento.'"></empty-records-component>


<paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
  (onPager)="loadPage($event)">
</paginator>