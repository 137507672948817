<div class="container-full flex flex-col justify-content-center">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>
  <h1 class="title-modal mt-2">{{ isNew ? 'Adicionar Nota' : 'Editar Nota' }}</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">
    {{ isNew ? 'Adicionar nova nota de atendimento' : 'Editar nota de atendimento' }}</h6>
</div>

<div class="flex items-center justify-between mb-4">
  <span class="data-time-note" *ngIf="!isNew">
  </span>

  <div class="flex items-center space-x-2" *ngIf="!isNew && !isEditing">
    <button mat-icon-button color="primary" (click)="toggleEditing()" title="Editar nota">
      <mat-icon color="primary">edit</mat-icon>
    </button>
  </div>
</div>

<ng-container *ngIf="isEditing; else viewMode">
  <form [formGroup]="formGroup" (ngSubmit)="save()" class="w-full">
    <div class="rounded-lg" [ngClass]="{ 'border p-2': !isNew && !isEditing }">
      <div class="flex justify-between items-center">
        <div class="flex flex-col space-y-4 w-full">
          <textarea class="input-field w-full mr-4 overflow-y-auto p-4 resize-none rounded"
            style="height: 200px; max-height: 200px;" matInput formControlName="description"
            placeholder="Descrição da Nota"></textarea>
        </div>
      </div>
    </div>

    <div class="flex space-x-4 w-full mt-4">
      <button type="button"
        class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
        (click)="cancel()">
        Cancelar
      </button>
      <button type="submit"
        class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
        [disabled]="formGroup.invalid || !isEditing || (loadingSpinner$ | async)">
        <ng-container *ngIf="!(loadingSpinner$ | async); else loadingTemplate">
          Salvar
        </ng-container>
        <ng-template #loadingTemplate>
          <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate"></mat-spinner>
        </ng-template>
      </button>

    </div>
  </form>
</ng-container>

<ng-template #viewMode>
  <div class="rounded-lg border p-2">
    <div class="flex justify-between items-center">
      <p class="flex-1 overflow-y-auto max-h-[200px] ml-1">{{ note?.description }}</p>
    </div>
  </div>
</ng-template>