import { Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, AttendanceService, Channel, ChannelService, CheckerContactTypeEnum, ConfirmationComponent, Contact, ContactEditComponent, ContactService, ContactUploadCsvComponent, Pager, Tag, TagService, UtilHelper } from 'lib-trend-core';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, mergeMap, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'contact-component',
  templateUrl: 'contact.component.html',
  styleUrl: 'contact.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent extends AbstractComponent implements OnInit {

  pager: Pager<Contact> = new Pager<Contact>({ perPage: 10 });

  filterControlTags: FormControl = new FormControl('');
  filterControlChannel: FormControl = new FormControl('');

  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
  selectedTagsId: Array<string> | null = new Array<string>();
  private selectedChannelId: string | null = null;
  currentUser: any

  listObservable: Observable<Pager<Contact>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  listTag: Array<Tag> = new Array<Tag>();
  allTags: Array<Tag> = new Array<Tag>();
  private totalAttendances!: Array<{ _id: string, count: number, channel: Channel }>;

  listChannel: Array<Channel> = new Array<Channel>();
  allChannel: Array<Channel> = new Array<Channel>();
  labelChannel: string = 'Canais';

  isPicker: boolean = false;

  totalContactValidated: number;
  totalContactNotValidated: number;
  totalContactInvalid: number;
  totalContact: number

  contactInfoChart: { validated: number, notValidated: number, invalid: number, total: number };

  radius: number;
  isFilter: string = '';
  toggleTableCard: boolean = false;
  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public contactService: ContactService,
    public attendanceService: AttendanceService,
    private tagService: TagService,
    private channelService: ChannelService,
  ) {
    super(injector);
    this.createForm();
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.currentUser = this.getCurrentUserUser();
    this.getListChannel();
    this.fetchFilterTags();
    this.fetchFilterChannel();
    this.setupCombinedFiltersAndSearch();
    const savedView = localStorage.getItem('tableCardView');
    this.toggleTableCard = savedView ? JSON.parse(savedView) : false;
  }

  openPicker(): void {
    this.isPicker = true;
    setTimeout(() => {
      this.picker.open();
    }, 300);
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [null],
      dateRange: this.formBuilder.group({
        dateStart: [null],
        dateEnd: [null],
      }),
      channel: [null],
      tags: [[]],
      valid: [null]
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.contactService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Contact>) => {
      this.pager = pager;
    });
  }

  private setupCombinedFiltersAndSearch(): void {
    combineLatest([
      this.formGroup.valueChanges.pipe(
        startWith(this.formGroup.value),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      ),
      this.termoDaBusca.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      )
    ])
      .pipe(
        tap(() => {
          this.pager.list = [];
        })
      )
      .subscribe(([formValues, searchTerm]) => {
        this.searchString = searchTerm;
        this.getList();
      });
  }


  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  private getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };

    const type = this.formGroup.get('type').value;
    if (type) {
      this.searchParams['type'] = type;
    }

    const tags = this.formGroup.get('tags')?.value;
    if (!!tags && tags.length > 0) {
      this.searchParams['tags'] = tags;
    }

    const channel = this.formGroup.get('channel')?.value
    if (channel) {
      this.searchParams['channel'] = channel;
    }

    const dateRange = this.formGroup.get('dateRange').value;
    if (dateRange?.dateStart && dateRange?.dateEnd) {
      this.searchParams['dateStart'] = dateRange.dateStart.getTime();
      this.searchParams['dateEnd'] = dateRange.dateEnd.getTime();
    }

    const valid = this.formGroup.get('valid')?.value
    if (valid) {
      this.searchParams['valid'] = valid;
    }

    const getValueTotalizer = (totalizer: Array<{ count: number, validStatus: string }>, checkerContactTypeEnum: CheckerContactTypeEnum) => {
      let result = 0;
      const filtered = totalizer.filter((total) => total.validStatus === checkerContactTypeEnum.toString());
      if (filtered.length > 0) {
        const mapped = filtered.map((total) => total.count);
        if (!!mapped && mapped.length > 0) {
          result = mapped.reduce((result) => result);
        }
      }
      return result;
    }

    this.contactService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(
        mergeMap((pager: Pager<Contact>) => {
          this.pager = pager;
          return this.contactService.getTotalizerContact();
        }),
        mergeMap((totalizer: Array<{ count: number, validStatus: string }>) => {
          this.totalContactValidated = getValueTotalizer(totalizer, CheckerContactTypeEnum.VALIDATED);
          this.totalContactNotValidated = getValueTotalizer(totalizer, CheckerContactTypeEnum.NOT_VALIDATED);
          this.totalContactInvalid = getValueTotalizer(totalizer, CheckerContactTypeEnum.INVALID);

          this.totalContact = this.totalContactValidated + this.totalContactNotValidated + this.totalContactInvalid

          this.contactInfoChart = {
            validated: (this.totalContactValidated / this.totalContact) * 100,
            notValidated: (this.totalContactNotValidated / this.totalContact) * 100,
            invalid: (this.totalContactInvalid / this.totalContact) * 100,
            total: (this.totalContact / this.totalContact) * 100
          }

          this.radius = Math.max(50, Math.floor(this.totalContact / 20));

          return this.tagService.getList();
        }),
        mergeMap((tags: Array<Tag>) => {
          this.listTag = tags;
          this.allTags = tags;
          const idsContacts = this.pager.list.map(contact => contact._id);
          return this.attendanceService.getCountAttendancesByContacts(idsContacts);
        }),
      ).subscribe((counts: any) => {
        this.totalAttendances = counts;
        this.loadingContent = false;
      });
  }

  add(): void {
    const dialogRef = this.dialog.open(ContactEditComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  import(): void {
    const dialogRef = this.dialog.open(ContactUploadCsvComponent, {
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  edit(item: Contact): void {
    const dialogRef = this.dialog.open(ContactEditComponent, {
      width: '700px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }

  view(item: Contact): void {
    this.router.navigate(['/contact/view', item._id]);
  }

  delete(item: Contact): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.contactService.delete(item._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success('Contato excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  getAddress(item: Contact): string {
    return UtilHelper.getContactAddress(item);
  }

  getChannelContact(contactId: string): string {
    if (this.totalAttendances && this.totalAttendances.length > 0) {
      const channel = this.totalAttendances
        .filter((value: { _id: string, count: number, channel: Channel }) => value && value._id === contactId)
        .map((value: { _id: string, count: number, channel: Channel }) => value.channel)
        .reduce((channelAcc, channelCurrent) => channelCurrent || channelAcc, null);

      return (channel && channel.name) && (channel.removed !== true) ? channel.name : '-';
    }

    return '-';
  }


  getTagsContact(contact: Contact): string {
    const tagsId = contact.tags.map(tag => tag);

    const tagTitle = this.listTag
      .filter((tag: any) => tagsId.includes(tag._id))
      .map(tag => tag.title)
      .join(', ');

    return tagTitle.length === 0 ? '-' : tagTitle;
  }

  getCountAttendades(contactId: string): string {
    if (this.totalAttendances) {
      const total = this.totalAttendances
        .filter((value) => value && value._id === contactId)
        .map((value) => value.count)
        .toString();

      return total.length === 0 ? 'Nenhum atendimento' : total;
    }

    return '-';
  }

  selectTag(event: MatCheckboxChange): void {
    const tagsControl = this.formGroup.get('tags');
    const currentTags = tagsControl.value;

    if (event.checked) {
      tagsControl.setValue([...currentTags, event.source.value]);
    } else {
      tagsControl.setValue(currentTags.filter((id) => id !== event.source.value));
    }
  }

  searchByChannel(channel: Channel): void {
    if (channel) {
      const firstWord = channel.name.split(' ')[0].slice(0, 6);
      this.labelChannel = firstWord;
      this.formGroup.get('channel').setValue(channel._id);
    } else {
      this.labelChannel = 'Canais';
      this.formGroup.get('channel').setValue(null);
    }
  }

  private getListChannel(): void {
    this.channelService.getList().subscribe({
      next: (list: Array<Channel>) => {
        this.allChannel = list;
        this.listChannel = list;
      },
      error: () => this.alertService.error('Ops! Não foi possível buscar os canais. Tente novamente mais tarde.'),
    });
  }

  onPeriodChange(event: any): void {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 7)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 15)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 30)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 60)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
    }
  }

  private fetchFilterTags(): void {
    this.filterControlTags.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      const currentValue = this.selectedTagsId || [];

      this.allTags = this.listTag.filter(tag =>
        tag.title.toLowerCase().includes(value.toLowerCase())
      );

      currentValue.forEach(tagId => {
        const existingTag = this.listTag.find(tag => tag._id === tagId);
        if (existingTag && !this.allTags.includes(existingTag)) {
          this.allTags.push(existingTag);
        }
      });
    });
  }

  onFocusTags(): void {
    this.allTags = this.listTag;
  }

  private fetchFilterChannel(): void {
    this.filterControlChannel.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.selectedChannelId || null;

      this.listChannel = this.allChannel.filter(channel =>
        channel.name.toLowerCase().includes(value.toLowerCase()) &&
        channel._id !== currentValue
      );

      const existingChannel = this.allChannel.find(channel => channel._id === currentValue);
      if (existingChannel) {
        this.listChannel.push(existingChannel);
      }
    });
  }

  onFocusChannel(): void {
    this.listChannel = this.allChannel;
  }

  searchByValid(validType: string): void {
    this.isFilter = validType
    this.formGroup.get('valid').setValue(validType || null);
  }

  clearSearchByValid() {
    this.isFilter = undefined;
    this.formGroup.get('valid').setValue(null);
  }

  getContactStatus(status: string | undefined): string {
    switch (status) {
      case 'VALIDATED':
        return 'Validado';
      case 'NOT_VALIDATED':
        return 'Não Validado';
      case 'INVALID':
        return 'Inválido';
      default:
        return 'Desconhecido';
    }
  }

  toggleTableCards() {
    this.toggleTableCard = !this.toggleTableCard;
    localStorage.setItem('tableCardView', JSON.stringify(this.toggleTableCard));
  }
}
