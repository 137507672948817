<div class="flex flex-1 flex-col px-5 p-2">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Contatos e Grupos</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      Organize seus contatos e grupos conforme sua processo de atendimento
    </h6>
    <hr />
  </div>

  <div class="w-full flex items-center">
    <section class="w-full flex flex-row flex-wrap">
      <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
      <label for="tab-one"
        class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-default px-4 rounded-t-lg block">
        Contatos
      </label>

      <input id="tab-two" type="radio" name="tabs" class="peer/tab-two opacity-0 absolute" />
      <label for="tab-two"
        class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-two:border-b-2 peer-checked/tab-two:border-[#4213F6] peer-checked/tab-two:text-[#4213F6] cursor-default px-4 rounded-t-lg block">
        Grupos de Contato
      </label>
      <button (click)="toggleTableCards()"
        class="border-[#bdbdbd] border-1 rounded-lg p-2 absolute right-12 top-[182px] flex items-center ">
        <mat-icon class="text-[#bdbdbd]">{{ toggleTableCard ? 'table_rows' : 'view_module' }}</mat-icon>
      </button>




      <div class="hidden peer-checked/tab-one:flex w-full items-center">
        <div class="flex flex-1 flex-col">
          <div class="w-full flex items-center">
            <form [formGroup]="formGroup" class="flex w-full flex-col mb-4">
              <div class="flex w-full flex-col">
                <div class="flex flex-1 w-100 justify-end mb-4"></div>
                <div class="flex gap-2">
                  <div class="flex flex-1">
                    <label for="simple-search" class="sr-only">Pesquisar</label>
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8.52625 14.4874C7.34723 14.4874 6.19468 14.1378 5.21436 13.4828C4.23404 12.8278 3.46997 11.8967 3.01878 10.8075C2.56759 9.71819 2.44953 8.51958 2.67955 7.36321C2.90957 6.20684 3.47732 5.14465 4.31101 4.31095C5.14471 3.47726 6.2069 2.9095 7.36327 2.67949C8.51964 2.44947 9.71825 2.56752 10.8075 3.01872C11.8968 3.46991 12.8278 4.23398 13.4829 5.2143C14.1379 6.19462 14.4875 7.34717 14.4875 8.52619C14.4875 9.30904 14.3333 10.0842 14.0337 10.8075C13.7341 11.5307 13.295 12.1879 12.7415 12.7414C12.1879 13.295 11.5308 13.7341 10.8075 14.0337C10.0843 14.3333 9.3091 14.4874 8.52625 14.4874ZM8.52625 3.76036C7.58679 3.76036 6.66843 4.03894 5.8873 4.56088C5.10616 5.08282 4.49734 5.82467 4.13783 6.69261C3.77831 7.56056 3.68425 8.51563 3.86752 9.43704C4.0508 10.3584 4.5032 11.2048 5.1675 11.8691C5.8318 12.5334 6.67817 12.9858 7.59958 13.1691C8.52099 13.3524 9.47605 13.2583 10.344 12.8988C11.2119 12.5393 11.9538 11.9305 12.4757 11.1493C12.9977 10.3682 13.2763 9.44982 13.2763 8.51036C13.2763 7.25058 12.7758 6.0424 11.885 5.1516C10.9942 4.26081 9.78603 3.76036 8.52625 3.76036Z"
                            fill="#757575" />
                          <path
                            d="M15.8333 16.427C15.7553 16.4274 15.678 16.4122 15.606 16.3823C15.5339 16.3523 15.4686 16.3084 15.4137 16.2528L12.1442 12.9833C12.0393 12.8707 11.9822 12.7218 11.9849 12.568C11.9876 12.4142 12.0499 12.2674 12.1587 12.1586C12.2675 12.0499 12.4143 11.9875 12.5681 11.9848C12.7219 11.9821 12.8708 12.0392 12.9833 12.1441L16.2529 15.4137C16.3641 15.525 16.4266 15.6759 16.4266 15.8333C16.4266 15.9906 16.3641 16.1415 16.2529 16.2528C16.1981 16.3084 16.1327 16.3523 16.0607 16.3823C15.9886 16.4122 15.9113 16.4274 15.8333 16.427Z"
                            fill="#757575" />
                        </svg>
                      </div>
                      <input type="search" #searchBox (keyup)="search(searchBox.value)" class="input-field pl-10"
                        placeholder="Pesquisar..." style="padding-left: 30px; box-sizing: border-box" />
                    </div>
                  </div>
                  <div class="flex">
                    <button type="submit" [matMenuTriggerFor]="tagsMenu"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900 global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span> Tags </span>
                    </button>
                    <mat-menu #tagsMenu="matMenu" [class]="'menu-tags'">
                      <div class="input-select-filter w-full p-2 mb-2">
                        <input class="input-select-filter input-field" type="text" (focus)="onFocusTags()"
                          [formControl]="filterControlTags" (click)="$event.stopPropagation()" />
                      </div>
                      @if (allTags.length > 0) { @for (tag of allTags; track
                      $index) {
                      <div class="flex flex-col">
                        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectTag($event)"
                          [value]="tag._id" [checked]="
                            formGroup.get('tags').value.includes(tag._id)
                          ">{{ tag.title }}</mat-checkbox>
                      </div>
                      } } @else {
                      <h6 class="text-zinc-400 font-light text-sm mt-[15px] p-2">
                        Nenhum resultado encontrado.
                      </h6>
                      }
                    </mat-menu>
                    <!-- Filter channel -->
                    <button type="submit" [matMenuTriggerFor]="channelMenu"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900 global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span>
                        {{ labelChannel }}
                      </span>
                    </button>
                    <mat-menu #channelMenu="matMenu">
                      <div class="input-select-filter w-full p-2 mb-2">
                        <input class="input-select-filter input-field" type="text" (focus)="onFocusChannel()"
                          [formControl]="filterControlChannel" (click)="$event.stopPropagation()" />
                      </div>
                      @if (listChannel.length > 0) {
                      <button mat-menu-item (click)="searchByChannel(null)">
                        Todos
                      </button>
                      @for (channel of listChannel; track $index) {
                      <button mat-menu-item (click)="searchByChannel(channel)">
                        {{ channel.name }}
                      </button>
                      } } @else {
                      <h6 class="text-zinc-400 font-light text-sm mt-[15px] p-2">
                        Nenhum resultado encontrado.
                      </h6>
                      }
                    </mat-menu>

                    <!-- Filter date -->
                    <div id="input-phone"
                      class="bg-white flex ml-2 items-center justify-center h-[100%] border rounded-lg w-[28%]">
                      <ng-container *ngIf="
                          !formGroup.get('dateRange')?.get('dateStart')
                            ?.value &&
                          !formGroup.get('dateRange')?.get('dateEnd')?.value &&
                          !isPicker
                        ">
                        <label class="text-[14px] w-full ml-2 font-semibold">Selecione um período</label>
                      </ng-container>
                      <ng-container *ngIf="isPicker">
                        <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
                          <input readonly matStartDate id="date-start" formControlName="dateStart"
                            (focus)="picker.open()" />
                          <input readonly matEndDate id="date-end" formControlName="dateEnd" (focus)="picker.open()" />
                        </mat-date-range-input>
                      </ng-container>

                      <mat-datepicker-toggle (click)="openPicker()" class="m-auto" matIconSuffix
                        [for]="picker"></mat-datepicker-toggle>
                      <mat-date-range-picker #picker (selectionChange)="onPeriodChange($event)">
                        <mat-datepicker-actions>
                          <mat-select class="input-field" placeholder="Selecione"
                            (selectionChange)="onPeriodChange($event)">
                            <mat-option value="1">Hoje</mat-option>
                            <mat-option value="7">Últimos 7 dias</mat-option>
                            <mat-option value="15">Últimos 15 dias</mat-option>
                            <mat-option value="30">Últimos 30 dias</mat-option>
                            <mat-option value="60">Últimos 60 dias</mat-option>
                          </mat-select>
                          <button mat-raised-button matDatepickerApply class="btnOk ml-2">
                            OK
                          </button>
                        </mat-datepicker-actions>
                      </mat-date-range-picker>
                    </div>
                    <button
                      class="ml-4 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
                      (click)="add()">
                      + Adicionar Contato
                    </button>
                    <button
                      class="ml-4 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
                      *ngIf="isAdmin()" (click)="import()">
                      + Importar Contatos
                    </button>
                  </div>
                </div>
              </div>
              <div class="w-full mt-4" *ngIf="!loadingContent">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:gap-3">
                  <card-graphic-small-component [label]="'Total'" style="box-shadow: none !important"
                    [total]="totalContact.toString()" [contactInfoChart]="contactInfoChart.total" [radius]="radius"
                    [graphicType]="'percentage'" [textColor]="'#000'" [totalContact]="pager.list.length" class="w-full"
                    [datasetTitle]="'contatos'" (click)="clearSearchByValid()"></card-graphic-small-component>
                  <card-graphic-small-component [label]="'Validados'" style="box-shadow: none !important"
                    [total]="totalContactValidated.toString()" [contactInfoChart]="contactInfoChart.validated"
                    [radius]="radius" [graphicType]="'percentage'" [textColor]="'#000'" [totalContact]="totalContact"
                    [datasetTitle]="'contatos'" (click)="searchByValid('VALIDATED')" class="card-hover-active"
                    [ngClass]="{'card-hover-active': isFilter === 'VALIDATED'}"></card-graphic-small-component>
                  <card-graphic-small-component [label]="'Não validados'" [total]="totalContactNotValidated.toString()"
                    [contactInfoChart]="contactInfoChart.notValidated" [radius]="radius" [graphicType]="'percentage'"
                    class="w-full" [textColor]="'#000'" [totalContact]="totalContact" [datasetTitle]="'contatos'"
                    (click)="searchByValid('NOT_VALIDATED')" class="card-hover-active"
                    [ngClass]="{'card-hover-active': isFilter === 'NOT_VALIDATED'}"></card-graphic-small-component>
                  <card-graphic-small-component [label]="'Inválidos'" [total]="totalContactInvalid.toString()"
                    [contactInfoChart]="contactInfoChart.invalid" [radius]="radius" [graphicType]="'percentage'"
                    class="w-full" [textColor]="'#000'" [totalContact]="totalContact" [datasetTitle]="'contatos'"
                    (click)="searchByValid('INVALID')"
                    [ngClass]="{'card-hover-active': isFilter === 'INVALID'}"></card-graphic-small-component>
                </div>
              </div>
            </form>
          </div>
          <content-loading-list *ngIf="loadingContent"></content-loading-list>

          @if(toggleTableCard){
          <div class="w-full max-h-max flex flex-wrap" *ngIf="pager.total > 0 && !loadingContent">
            <div class="w-full px-0">
              <div class="flex flex-wrap justify-around items-start gap-3 w-full mx-auto">
                <div
                  class="bg-white p-3 cursor-pointer shadow rounded-lg custom-max:w-[19%] custom-min:w-[30%] md:w-[calc(50%-16px)] sm:w-[100%] mobile:w-[100%] h-auto border-2 border-gray-100"
                  *ngFor="let item of pager.list">
                  <div (click)="view(item)">
                    <div class="flex w-100 justify-between items-center pb-2 border-b-2 border-gray-100">
                      <div class="flex gap-3 flex-1">
                        <img class="w-8 h-8 rounded-full" [src]="getContactImage(item)" alt="{{ item?.name }}"
                          (error)="onImageError($event)" />
                        <div class=" flex justify-between w-full">
                          <div class="flex-col h-full">
                            <div class="text-sm text-ellipsis overflow-hidden">
                              {{ item?.name | truncate : 10 }}
                            </div>
                            <div class="text-[12px] text-gray-400">
                              {{ item.createdAt | date : "dd/MM/yyyy" }}
                            </div>
                          </div>
                          <div class="rounded p-2 text-[10px] font-normal text-white flex items-center h-6" [ngClass]="{
                      'bg-green-500': item?.valid === 'VALIDATED',
                      'bg-yellow-500': item?.valid === 'NOT_VALIDATED',
                      'bg-red-500': item?.valid === 'INVALID'
                    }">
                            {{ getContactStatus(item?.valid) }}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="flex flex-col h-full justify-start items-start mt-2 gap-2">
                      <div *ngIf="item.phone">
                        <label class="text-sm text-gray-400">Telefone:</label>
                        <h5 class="font-semibold text-sm">
                          {{
                          (isAttendant() &&
                          !currentUser?.clientVisibilitys) ||
                          (!isAttendant() && (isSupervisor() || isAdmin()))
                          ? item.phone
                          : "-"
                          }}
                        </h5>
                      </div>

                      <div>
                        <label class="text-sm text-gray-400">Canal:</label>
                        <h5 class="font-semibold text-sm">
                          {{ getChannelContact(item._id) }}
                        </h5>
                      </div>
                      <label class="text-sm text-gray-400">Etiquetas:</label>
                      <div *ngIf="item.tags.length > 0; else noTags" class="flex w-full">
                        <div class="tags-container flex overflow-hidden">
                          <h5 *ngFor="
                          let tag of item.tags | slice : 0 : 3;
                          let i = index
                        " class="font-semibold text-sm mr-1 truncate whitespace-nowrap overflow-hidden"
                            [matTooltip]="tag.title">
                            {{ tag.title | slice : 0 : 15
                            }}<span *ngIf="i < item.tags.length - 1">,</span>
                          </h5>
                          <span *ngIf="item.tags.length > 3" class="font-semibold text-sm truncate" matTooltip="Mais {{
                          item.tags.length - 3
                        }} etiquetas">
                            +{{ item.tags.length - 3 }}
                          </span>
                        </div>
                      </div>
                      <ng-template #noTags>
                        <h5 class="font-semibold text-sm">-</h5>
                      </ng-template>

                      <div>
                        <label class="text-sm text-gray-400">Atendimentos:</label>
                        <h5 class="font-semibold text-sm">
                          {{ getCountAttendades(item._id) }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3 "
                [pager]="pager" (onPager)="loadPage($event)">
              </paginator>
            </div>
          </div>
          }@else{
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2">
            <table class="min-w-full divide-gray-100 divide-y-[3px]">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Criado em</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Status</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Telefone</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Canal</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Etiquetas</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Total de atendimentos
                  </th>

                </tr>
              </thead>
              <tbody class="divide-gray-100 divide-y-[3px] bg-white">
                <tr class="rounded-none cursor-pointer" *ngFor="let item of pager.list" (click)="view(item)">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{ item?.name | truncate
                    : 20 }}</td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{ item.createdAt | date
                    : "dd/MM/yyyy" }}</td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light">
                    <span class="px-[10px] py-[5px] text-white rounded-[5px]" [ngClass]="{
                        'bg-green-500': item?.valid === 'VALIDATED',
                        'bg-yellow-500': item?.valid === 'NOT_VALIDATED',
                        'bg-red-500': item?.valid === 'INVALID'
                      }">
                      {{ getContactStatus(item?.valid) }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                    {{
                    (isAttendant() && !currentUser?.clientVisibilitys) ||
                    (!isAttendant() && (isSupervisor() || isAdmin()))
                    ? item.phone
                    : "-"
                    }}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{
                    getChannelContact(item._id) }}</td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                    <ng-container *ngIf="item.tags.length > 0; else noTags">
                      <div class="flex overflow-hidden">
                        <span *ngFor="let tag of item.tags | slice: 0 : 3; let i = index"
                          class="font-semibold text-xs truncate whitespace-nowrap overflow-hidden"
                          [matTooltip]="tag.title">
                          {{ tag.title | slice: 0 : 15 }}<span *ngIf="i < item.tags.length - 1">, </span>
                        </span>
                        <span *ngIf="item.tags.length > 3" class="font-semibold text-xs truncate"
                          matTooltip="Mais {{ item.tags.length - 3 }} etiquetas">
                          +{{ item.tags.length - 3 }}
                        </span>
                      </div>
                    </ng-container>
                    <ng-template #noTags>
                      <span class="font-light">-</span>
                    </ng-template>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{
                    getCountAttendades(item._id) }}</td>
                </tr>
              </tbody>
            </table>
            <paginator *ngIf="pager.total > pager.perPage" class="flex justify-center mt-3 mb-3" [pager]="pager"
              (onPager)="loadPage($event)">
            </paginator>
          </div>
          }

          <empty-records-component *ngIf="pager.total == 0" [message]="'Você ainda não tem contatos adicionados'"
            [subMessage]="'Adicione seu primeiro'" [labelButton]="'+ Adicionar'" (emitEvent)="add()">
          </empty-records-component>
        </div>
      </div>
      <div class="hidden peer-checked/tab-two:block w-full">
        <contact-group-component></contact-group-component>
      </div>
    </section>
  </div>
</div>